@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.btn-transform {
  text-transform: capitalize !important;
}

.p-joke {
  padding: 32px 5px;
  color: #fff;
}

.mb-3 {
  margin-bottom: 3px;
}
